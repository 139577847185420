import { useEffect, useState } from 'react';

export const useGetCurrentUrl = () => {
    const [currentUrl, setCurrentUrl] = useState('');

    useEffect(() => {
        const cleanUrl = `${window.location.origin}${window.location.pathname}`;
        setCurrentUrl(cleanUrl);
    }, []);

    return currentUrl;
};
