import { Tariff } from '@/entities';
import Button from '@/shared/components/shared/Button';
import TarifCard from '@/shared/components/shared/TarifCard';
import { useTypograf } from '@/shared/hooks/use-typograf';
import Link from '../../shared/Link';

export type TarifSectionProps = {
    title: string;
    subtitle: string;
    cards: { title: Tariff['title']; description: Tariff['description']; href: string }[];
};

type Props = React.HTMLAttributes<HTMLElement> & {
    tarifSectionProps: TarifSectionProps;
};

const TarifSection = ({ tarifSectionProps, ...props }: Props) => {
    const tpTitle = useTypograf(tarifSectionProps.title);
    const tpSubtitle = useTypograf(tarifSectionProps.subtitle);

    return (
        <section
            className="tarif-section-wrapper js-header-theme-trigger js-bg-trigger-light"
            data-bg="#fff"
            data-theme="dark"
            {...props}
        >
            <div className="tarif-top wrapper">
                <div className="tarif-title h3">{tpTitle}</div>
                <div className="tarif-description-block">
                    <div className="tarif-description text-s">{tpSubtitle}</div>
                    <Button
                        tag={Link}
                        href={'https://lk.oilgasforum.ru/auth/'}
                        target="_blank"
                        rel="noreferrer nofollow"
                        className="tarif-section-btn"
                        variant="outline-dark"
                    >
                        Личный кабинет
                    </Button>
                </div>
            </div>
            <div className="tarif-bottom">
                {tarifSectionProps.cards.map((card, i) => (
                    <TarifCard
                        key={i}
                        card={{
                            ...card,
                            description: card.description!,
                        }}
                    />
                ))}
            </div>
        </section>
    );
};

export default TarifSection;
