import ArrSVG from '@public/icons/btn-arr.svg';
import classNames from 'classnames';
import {
    AnimatePresence,
    AnimationProps,
    MotionValue,
    animate,
    m as motion,
    useMotionValue,
    wrap,
} from 'framer-motion';
import { useDeferredValue, useRef, useState } from 'react';
import { CommunityEvent } from '@/entities/community-event';
import Button from '@/shared/components/shared/Button';
import Image from '@/shared/components/shared/Image';
import Link from '@/shared/components/shared/Link';
import Pagination from '@/shared/components/shared/Pagination';
import Responsive from '@/shared/components/shared/Responsive';
import { useTypograf } from '@/shared/hooks/use-typograf';
import { formatDateRange } from '@/shared/lib/utils/dates';
import { easeOutQuart } from '@/shared/lib/utils/easings';

export type OtherEventsSectionProps = {
    otherEventsSlider: CommunityEvent[];
    title: string;
    description: string;
};

type Props = React.HTMLAttributes<HTMLElement> & {
    otherEventsSectionProps: OtherEventsSectionProps;
};

const SlideContent = ({
    index,
    data,
    x,
    isGoingBackwards,
    ...props
}: React.HTMLAttributes<HTMLElement> & {
    index: number;
    data: CommunityEvent;
    x: MotionValue;
    isGoingBackwards: boolean;
}) => {
    return (
        <AnimatePresence mode="popLayout">
            <motion.div
                {...(props as AnimationProps)}
                key={index}
                variants={{
                    initial: {
                        x: `${(isGoingBackwards ? -1 : 1) * 100}%`,
                        opacity: 0,
                    },
                    animate: {
                        x: 0,
                        opacity: 1,
                    },
                    exit: {
                        x: `${(isGoingBackwards ? 1 : -1) * 100}%`,
                    },
                }}
                initial="initial"
                animate="animate"
                exit="exit"
                transition={{ duration: 0.5 }}
                className={classNames('other-events-slider-slide-content-el', props.className)}
            >
                <motion.div className="other-events-slider-slide-content-el-inner" style={{ x }}>
                    {data.img?.src && (
                        <div className="other-events-slider-img-wrapper responsive__item">
                            <Image
                                className="other-events-slider-img responsive__item"
                                src={data.img.src}
                                alt={data.img.alt || data.title || ''}
                                title={data.title}
                                width={data.img.width}
                                height={data.img.height}
                                draggable={false}
                                sizes="(max-width: 1199px) 40vw, 30vw"
                            />
                        </div>
                    )}
                    <div className="news-slide-btn">
                        <ArrSVG />
                    </div>
                    <div className="other-events-slide-bottom-wrapper">
                        <div className="other-events-slide-title text-xl">{data.title}</div>
                        <div className="other-events-slide-date text-m">
                            {formatDateRange(data.startDate, data.endDate)}
                        </div>
                    </div>
                </motion.div>
            </motion.div>
        </AnimatePresence>
    );
};

function OtherEventsSection({ otherEventsSectionProps }: Props) {
    const sliderRef = useRef<HTMLDivElement>(null);
    const [lastRealIndex, setLastRealIndex] = useState(0);
    const [currentRealIndex, setCurrentRealIndex] = useState(0);
    const deferredCurrentRealIndex = useDeferredValue(currentRealIndex);
    const numSlides = otherEventsSectionProps.otherEventsSlider.length;
    const tpTitle = useTypograf(otherEventsSectionProps.title);
    const tpDescription = useTypograf(otherEventsSectionProps.description);
    const THRESHOLD = 20;
    const x = useMotionValue(0);
    const getIndex = (index: number) => wrap(0, numSlides, index);
    const isGoingBackwards =
        ((lastRealIndex ?? 0) > currentRealIndex && !(lastRealIndex === numSlides - 1 && currentRealIndex === 0)) ||
        (lastRealIndex === 0 && currentRealIndex === numSlides - 1);
    const sliderInitialized = numSlides > 1;
    const [panned, setPanned] = useState(false);

    const calculatedSlides = otherEventsSectionProps.otherEventsSlider;

    return (
        <section className="other-events-section wrapper js-header-theme-trigger" data-theme="light">
            <div className="other-events-left">
                <h2 className="other-events-title h3">{tpTitle}</h2>
                <div className="other-events-description-block">
                    <div className="other-events-description text-m">{tpDescription}</div>
                    <Button tag={Link} href="/community/" variant="outline-light">
                        Календарь
                    </Button>
                </div>
                {sliderInitialized && (
                    <Pagination
                        numSlides={numSlides}
                        currentRealIndex={currentRealIndex}
                        lastRealIndex={lastRealIndex}
                        prevElClass="other-events-slider-prev-btn"
                        nextElClass="other-events-slider-next-btn"
                        wrapperClass="other-events-pagination-wrapper"
                        onPrevClick={() => {
                            setLastRealIndex(currentRealIndex);
                            setCurrentRealIndex((prevCurrentRealIndex) => getIndex(prevCurrentRealIndex - 1));
                        }}
                        onNextClick={() => {
                            setLastRealIndex(currentRealIndex);
                            setCurrentRealIndex((prevCurrentRealIndex) => getIndex(prevCurrentRealIndex + 1));
                        }}
                    />
                )}
            </div>
            <div className="other-events-right">
                <motion.div
                    ref={sliderRef}
                    className="other-events-slider"
                    onPan={(event, info) => {
                        setPanned(true);
                        if (sliderInitialized) {
                            x.set(info.offset.x * 0.2);
                        }
                    }}
                    onPanEnd={(event, info) => {
                        setPanned(false);
                        if (sliderInitialized) {
                            animate(x.get(), 0, {
                                duration: 0.3,
                                ease: easeOutQuart,
                                onUpdate: (val) => {
                                    x.set(val);
                                },
                            });

                            if (info.offset.x < -THRESHOLD) {
                                setLastRealIndex(currentRealIndex);
                                setCurrentRealIndex(getIndex(currentRealIndex + 1));
                            } else if (info.offset.x > THRESHOLD) {
                                setLastRealIndex(currentRealIndex);
                                setCurrentRealIndex(getIndex(currentRealIndex - 1));
                            }
                        }
                    }}
                >
                    {calculatedSlides.map((slide, i) => {
                        const index = i + currentRealIndex;
                        const deferredIndex = i + deferredCurrentRealIndex;
                        const deferredData = calculatedSlides[getIndex(deferredIndex)];
                        const isButton = i > 0 || !deferredData.hasDetail;

                        return (
                            <div
                                key={i}
                                className="other-events-slider-slide"
                                style={{ pointerEvents: panned ? 'none' : undefined }}
                            >
                                <Responsive
                                    tag={isButton ? 'button' : Link}
                                    href={isButton ? undefined : `/community/${deferredData.slug}/`}
                                    className="other-events-slider-slide-content"
                                    onDragStart={(event) => {
                                        event.preventDefault();
                                    }}
                                    onClick={() => {
                                        if (isButton) {
                                            setLastRealIndex(currentRealIndex);
                                            setCurrentRealIndex(index % numSlides);
                                        }
                                    }}
                                >
                                    <div className="other-events-slider-slide-content__item">
                                        <SlideContent
                                            index={deferredIndex}
                                            data={deferredData}
                                            x={x}
                                            isGoingBackwards={isGoingBackwards}
                                        />
                                    </div>
                                </Responsive>
                            </div>
                        );
                    })}
                </motion.div>
            </div>
        </section>
    );
}

export default OtherEventsSection;
