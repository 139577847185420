import { forwardRef, useEffect, useRef, useState } from 'react';
import Image from '@/shared/components/shared/Image';
import { ImageShape, VideoShape } from '@/shared/lib/types';
import { mergeRefs } from '@/shared/lib/utils/merge-refs';
import { Event } from '../../../../entities/event';

type Props = React.VideoHTMLAttributes<HTMLVideoElement> & {
    /**
     * Массив сурсов
     */
    sources: VideoShape;
    /**
     * Превью
     */
    previewImg?: ImageShape | null;
    previewImgSizes?: string;
    /**
     * Автопроигрывание
     */
    autoPlay?: boolean;
};

const Video = forwardRef<HTMLVideoElement, Props>(
    ({ sources, previewImg, previewImgSizes = '100vw', autoPlay, ...props }, ref) => {
        const videoElRef = useRef<HTMLVideoElement>(null);
        const [fallbackToPreviewImg, setFallbackToPreviewImg] = useState(false);
        const [videoCanPlay, setVideoCanPlay] = useState(false);

        useEffect(() => {
            const autoplayedVideosPlayStateObserver = new IntersectionObserver(
                (entries, obs) => {
                    entries.forEach((entry) => {
                        const { target } = entry;
                        if (target instanceof HTMLVideoElement) {
                            if (entry.isIntersecting) {
                                if (target.autoplay && (target.loop || (!target.loop && !target.ended))) {
                                    target.play().catch((err) => {
                                        if (err.name === 'NotAllowedError') {
                                            obs.unobserve(target);

                                            if (!target.hasAttribute('data-video-preserve')) {
                                                setFallbackToPreviewImg(true);
                                            }
                                        }
                                    });
                                }
                            } else {
                                target.pause();
                            }
                        }
                    });
                },
                { rootMargin: '100px' },
            );

            if (videoElRef.current) {
                autoplayedVideosPlayStateObserver.observe(videoElRef.current);
            }

            return () => {
                autoplayedVideosPlayStateObserver.disconnect();
            };
        }, [autoPlay]);

        useEffect(() => {
            const video = videoElRef.current;
            if (video) {
                if (autoPlay) {
                    if (video.readyState === 4) {
                        setVideoCanPlay(true);
                    } else {
                        const handleCanPlay = () => {
                            setVideoCanPlay(true);
                            video?.removeEventListener('canplay', handleCanPlay);
                        };

                        video.addEventListener('canplay', handleCanPlay, { once: true });

                        return () => {
                            video.removeEventListener('canplay', handleCanPlay);
                        };
                    }
                }
            }
        }, [videoCanPlay, autoPlay]);

        return (
            <>
                {previewImg?.src && (!videoCanPlay || fallbackToPreviewImg) && (
                    <Image
                        src={previewImg.src}
                        width={previewImg.width}
                        height={previewImg.height}
                        alt={previewImg.alt || ''}
                        title={previewImg.title}
                        sizes={previewImgSizes}
                        className="poster-wrapper"
                    />
                )}
                {sources.length > 0 && !fallbackToPreviewImg ? (
                    <video ref={mergeRefs([ref, videoElRef])} playsInline autoPlay={autoPlay} {...props}>
                        {sources.map((obj, i) => (
                            <source
                                key={i}
                                src={`${obj.src}${obj.src.includes('#t=') ? '' : '#t=0.001'}`}
                                type={obj.type}
                            ></source>
                        ))}
                    </video>
                ) : fallbackToPreviewImg && previewImg?.src ? (
                    <Image
                        src={previewImg.src}
                        width={previewImg.width}
                        height={previewImg.height}
                        alt={previewImg.alt || ''}
                        title={previewImg.title}
                        sizes={previewImgSizes}
                        className="poster-wrapper"
                    />
                ) : null}
            </>
        );
    },
);

Video.displayName = 'Video';

export default Video;
