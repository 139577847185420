import ArrSVG from '@public/icons/btn-arr.svg';
import Link from '@/shared/components/shared/Link';
import { useTypograf } from '@/shared/hooks/use-typograf';
import { encodeUrl } from '@/shared/lib/utils/filter/chpu/encode-url';

interface Props {
    card: {
        date: string;
        title: string;
        description: string;
        dayId: number;
    };
}

function ProgramCard({ card }: Props) {
    const tpTitle = useTypograf(card.title);
    const tpDescription = useTypograf(card.description);
    const [numberSmall, numberLarge] = card.date.split('.');

    return (
        <Link href={encodeUrl('/program/', { day: card.dayId })} className="program-card">
            <div className="program-card-date">
                <div className="number-small">{numberSmall}</div>
                <div className="text-s">.{numberLarge}</div>
            </div>
            <div className="program-card-text">
                {tpTitle && <div className="program-card-title text-xl">{tpTitle}</div>}
                {tpDescription && <div className="program-card-description text-s">{tpDescription}</div>}
            </div>
            <div className="program-card-btn">
                <ArrSVG />
            </div>
        </Link>
    );
}

export default ProgramCard;
