import PlaySVG from '@public/icons/play.svg';
import { MotionValue, m as motion, useMotionValueEvent, useTransform } from 'framer-motion';
import React, { useEffect, useRef, useState } from 'react';
import { useMediaQueryDeviceStateValue } from '@/shared/atoms/media-query-device';
import Lazy from '../../shared/Lazy';

type Props = React.HTMLAttributes<HTMLElement> & {
    shortVideo: string;
    longVideo?: string;
    scrollYProgress: MotionValue;
};

function VideoClipSection({ shortVideo, longVideo, scrollYProgress }: Props) {
    const wrapperRef = useRef<HTMLDivElement>(null);
    const shortVideoRef = useRef<HTMLVideoElement>(null);
    const longVideoRef = useRef<HTMLVideoElement>(null);
    const longVideoWrapperRef = useRef<HTMLDivElement>(null);
    const device = useMediaQueryDeviceStateValue();
    const isMobile = device.includes('mobile');
    const isTablet = device.includes('tablet');
    const isDesktop = device === 'desktop';
    const [isPlayVisible, setPlayVisible] = useState(false);
    const width = isDesktop ? 30 : isTablet ? 10 : 0;

    const VIDEO_REVEAL_THRESHOLD = isMobile ? 0 : 0.7;
    const insetVertical = useTransform(scrollYProgress, [0.5, 0.7], [isMobile ? 10 : 30, 0]);
    const insetHorizontal = useTransform(scrollYProgress, [VIDEO_REVEAL_THRESHOLD, 1], [width, 0]);
    const roundCorners = useTransform(scrollYProgress, [VIDEO_REVEAL_THRESHOLD, 1], [18, 0]);
    const yPos = useTransform(scrollYProgress, [0, 1], [isDesktop ? 200 : isTablet ? 100 : 500, 0]);

    const clipPath = useTransform(
        [insetVertical, insetHorizontal, insetVertical, insetHorizontal, roundCorners],
        ([top, right, bottom, left, round]) => {
            return `inset(${top}% ${right}% ${bottom}% ${left}% round ${round}px)`;
        },
    );

    useMotionValueEvent(scrollYProgress, 'change', (progress) => {
        setPlayVisible(progress >= 0.94);
    });

    useEffect(() => {
        const observer = new IntersectionObserver(
            (entries) => {
                entries.forEach((entry) => {
                    if (entry.isIntersecting) {
                        shortVideoRef.current?.play();
                    } else {
                        shortVideoRef.current?.pause();
                    }
                });
            },
            { rootMargin: '0% 0% 0% 0%' },
        );

        if (wrapperRef.current) {
            observer.observe(wrapperRef.current);
        }

        return () => {
            observer.disconnect();
        };
    }, []);

    useEffect(() => {
        const handleFullscreenChange = () => {
            if (document.fullscreenElement !== longVideoRef.current) {
                longVideoRef.current?.pause();
            }
        };

        document.addEventListener('fullscreenchange', handleFullscreenChange);
        return () => {
            document.removeEventListener('fullscreenchange', handleFullscreenChange);
        };
    }, []);

    const handlePlayClick = () => {
        if (longVideoRef.current) {
            const video = longVideoRef.current;
            if (video.requestFullscreen) {
                video.requestFullscreen();
            } else if ((video as any).webkitEnterFullscreen) {
                (video as any).webkitEnterFullscreen();
            }
            video.play();
        }
    };

    return (
        <motion.div
            ref={wrapperRef}
            className="second-section-wrapper"
            style={{
                zIndex: 4,
                clipPath,
                y: yPos,
                position: 'relative',
            }}
        >
            {isPlayVisible && (
                <button className="video-play-btn" onClick={handlePlayClick}>
                    <PlaySVG width={60} height={60} />
                </button>
            )}
            <Lazy ref={shortVideoRef} offset={100}>
                <video className="video-clip-short" autoPlay muted playsInline loop data-src={shortVideo}></video>
            </Lazy>

            <Lazy ref={longVideoRef} offset={100}>
                <video className="video-clip-long" data-src={longVideo}></video>
            </Lazy>
        </motion.div>
    );
}

export default VideoClipSection;
