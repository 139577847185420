import { useEffect, useState } from 'react';

export const useGetUtmParams = () => {
    const [utmGetParams, setGetUtmParams] = useState<string>('');

    useEffect(() => {
        const storedUtmParams = localStorage.getItem('utmParams');
        if (storedUtmParams) {
            const storedUtmParamsStringify = JSON.parse(storedUtmParams);
            setGetUtmParams(storedUtmParamsStringify);
        }
    }, []);
    return utmGetParams;
};
