import classNames from 'classnames';
import { m as motion, useMotionValueEvent, useScroll } from 'framer-motion';
import { useRef, useState } from 'react';
import { useMediaQueryDeviceStateValue } from '@/shared/atoms/media-query-device';
import Button from '@/shared/components/shared/Button';
import { useTypograf } from '@/shared/hooks/use-typograf';
import { ImageShape, VideoShape } from '@/shared/lib/types';
import Link from '../../shared/Link';
import Video from '../../shared/Video';

export type FirstSectionProps = {
    video: VideoShape;
    title: string;
    info: string;
    location: string;
    date: string;
    text: string;
    poster?: ImageShape;
};

type Props = React.HTMLAttributes<HTMLElement> & {
    data: FirstSectionProps;
};

const FirstSection = ({ data }: Props) => {
    const firstSectionRef = useRef<HTMLElement | null>(null);
    const [isVideoHidden, setIsVideoHidden] = useState(false);
    const [animationState, setAnimationState] = useState('visible');
    const { scrollYProgress } = useScroll({ target: firstSectionRef, offset: ['start -30%', 'end end'] });
    const tpTitle = useTypograf(data.title);
    const posterVideo = data.poster || null;

    const tpInfo = useTypograf(data.info);
    const tpLocation = useTypograf(data.location);
    const tpDate = useTypograf(data.date);
    const device = useMediaQueryDeviceStateValue();
    const isDesktop = device === 'desktop';
    const endPosition = isDesktop ? -300 : 0;
    const endPosition2 = isDesktop ? -600 : 0;
    const tpText = useTypograf(data.text);

    const textVariants = {
        hidden: { opacity: 0, y: endPosition, transition: { duration: 1 } },
        visible: { opacity: 1, y: 0, transition: { duration: 1 } },
    };

    const buttonVariants = {
        hidden: { opacity: 0, y: endPosition2, transition: { duration: 1 } },
        visible: { opacity: 1, y: 0, transition: { duration: 1 } },
    };

    useMotionValueEvent(scrollYProgress, 'change', (progress) => {
        setIsVideoHidden(progress < 0.1);
        if (progress === 0) {
            setAnimationState('hidden');
        } else {
            setAnimationState('visible');
        }
    });

    return (
        <section className="first-section-wrapper js-header-theme-trigger" data-theme="light" ref={firstSectionRef}>
            <div className={classNames('showreel-video-wrapper', { 'hidden-video': isVideoHidden })}>
                <Video
                    sources={data.video}
                    className="showreel-video"
                    preload="metadata"
                    autoPlay
                    muted
                    playsInline
                    loop
                    previewImg={posterVideo}
                ></Video>
            </div>
            <div className="hero-title-block">
                <motion.div
                    className="hero-title-text"
                    variants={textVariants}
                    initial="visible"
                    animate={animationState}
                >
                    <h1 className="hero-title-left text-l">{tpInfo}</h1>
                    <div className="hero-title-right text-l">
                        <p>{tpLocation}</p>
                        <p>{tpDate}</p>
                    </div>
                </motion.div>
                <h2 className="hero-title h1">{tpTitle}</h2>
            </div>
            <motion.div className="hero-btn" variants={buttonVariants} initial="visible" animate={animationState}>
                <Button tag={Link} href="/registration/" variant="outline-light">
                    Принять участие
                </Button>
            </motion.div>
            <div className="gradient-section-top">
                <h2 className="visually-hidden">Промышленно-энергетический форум - ТНФ 2025</h2>
                <div className="gradient-section-text text-m">{tpText}</div>
            </div>
        </section>
    );
};

export default FirstSection;
