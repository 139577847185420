import classNames from 'classnames';
import Button from '@/shared/components/shared/Button';
import Link from '@/shared/components/shared/Link';
import ProgramCard from '@/shared/components/shared/ProgramCard';
import { useTypograf } from '@/shared/hooks/use-typograf';

export type ProgramSectionProps = {
    title: string;
    subtitle: string;
    cards: { date: string; title: string; description: string; dayId: number }[];
};

type Props = React.HTMLAttributes<HTMLElement> & {
    programSectionProps: ProgramSectionProps;
};

const ProgramSection = ({ programSectionProps, ...props }: Props) => {
    const tpTitle = useTypograf(programSectionProps.title);
    const tpSubtitle = useTypograf(programSectionProps.subtitle);
    const numSlides = programSectionProps.cards?.length;

    return (
        numSlides > 0 && (
            <section
                className={classNames('program-section-wrapper wrapper js-bg-trigger-light', props.className)}
                data-bg="#dfe1e3"
                id="program"
                {...props}
            >
                <div className="program-top">
                    <h2 className="program-title h3">{tpTitle}</h2>
                    <div className="program-description-block">
                        <div className="program-description text-s">{tpSubtitle}</div>
                        <Button tag={Link} href="/program" variant="outline-dark">
                            Вся программа
                        </Button>
                    </div>
                </div>
                <div className="program-bottom">
                    {programSectionProps.cards.map((card, i) => (
                        <ProgramCard card={card} key={i} />
                    ))}
                </div>
            </section>
        )
    );
};

export default ProgramSection;
