'use client';

import classNames from 'classnames';
import { HTMLAttributes, ReactElement, cloneElement, forwardRef, memo, useEffect, useRef } from 'react';
import { useIsClient } from 'usehooks-ts';
import LazyLoad from 'vanilla-lazyload';
import { mergeRefs } from '@/shared/lib/utils/merge-refs';

/**
 * Компонент для ленивой загрузки изображений и видео
 */

interface Props extends HTMLAttributes<HTMLElement> {
    children: ReactElement;
    offset?: number;
}

const Lazy = forwardRef<HTMLVideoElement, Props>(({ children, ...props }, ref) => {
    const videoEl = useRef<HTMLVideoElement>(null);
    const isClient = useIsClient();

    useEffect(() => {
        const videoLazyload = isClient
            ? new LazyLoad({
                  elements_selector: 'video.lazy',
                  threshold: props.offset || 1200,
                  container: videoEl.current?.parentElement ?? document.body,
              })
            : null;
        return () => {
            videoLazyload?.destroy();
        };
    }, [isClient]);

    return cloneElement(children, {
        ...props,
        ref: mergeRefs([ref, videoEl]),
        className: classNames(children.props.className, props.className, 'lazy'),
    });
});

Lazy.displayName = 'Lazy';

export default memo(Lazy);
