import ArrSVG from '@public/icons/btn-arr.svg';
import { useState } from 'react';
import { Navigation } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Article } from '@/entities';
import Button from '@/shared/components/shared/Button';
import Image from '@/shared/components/shared/Image';
import Link from '@/shared/components/shared/Link';
import Pagination from '@/shared/components/shared/Pagination';
import Responsive from '@/shared/components/shared/Responsive';
import Typografed from '@/shared/components/shared/Typografed';
import { useMounted } from '@/shared/hooks/use-mounted';
import { useTypograf } from '@/shared/hooks/use-typograf';

export type NewsSectionProps = {
    title: string;
    btnSlug: string;
    newsSlider: Article[];
};

type Props = React.HTMLAttributes<HTMLElement> & {
    newsSectionProps: NewsSectionProps;
};

function NewsSection({ newsSectionProps }: Props) {
    const tpTitle = useTypograf(newsSectionProps.title);
    const [currentRealIndex, setCurrentRealIndex] = useState(0);
    const [lastRealIndex, setLastRealIndex] = useState(0);
    const numSlides = newsSectionProps.newsSlider.length;
    const isMounted = useMounted();

    return (
        <section className="news-section js-header-theme-trigger js-bg-trigger" data-bg="#ffffff" data-theme="dark">
            <div className="news-top wrapper">
                <h2 className="news-title h3">{tpTitle}</h2>
                <div className="news-description-block">
                    <Button tag={Link} href={newsSectionProps.btnSlug} variant="outline-dark">
                        Все новости
                    </Button>
                </div>
            </div>
            {numSlides > 4 && isMounted ? (
                <div className="news-bottom">
                    <Swiper
                        className="news-slider"
                        modules={[Navigation]}
                        navigation={{
                            prevEl: '.news-slider-prev-btn',
                            nextEl: '.news-slider-next-btn',
                        }}
                        speed={650}
                        breakpoints={{
                            320: {
                                spaceBetween: 12,
                                slidesOffsetBefore: 16,
                            },
                            769: {
                                spaceBetween: 12,
                                slidesOffsetBefore: 22,
                            },
                            1201: {
                                spaceBetween: 20,
                                slidesOffsetBefore: 70,
                            },
                        }}
                        onSlideChange={(swiper) => {
                            const newRealIndex = swiper.realIndex;
                            setLastRealIndex(currentRealIndex);
                            setCurrentRealIndex(newRealIndex);
                        }}
                        slidesPerView={'auto'}
                    >
                        {newsSectionProps.newsSlider.map((slide, i) => (
                            <SwiperSlide className="news-slider-slide" key={i}>
                                <Link href={`/news/${slide.categories[0].slug}/${slide.slug}/`}>
                                    <Responsive className="news-slider-img-wrapper">
                                        {slide.img?.src && (
                                            <Image
                                                className="news-slider-img"
                                                src={slide.img.src}
                                                alt={slide.img.alt || ''}
                                                title={slide.img.title}
                                                width={slide.img.width}
                                                height={slide.img.height}
                                                sizes="(max-width: 767px) 80vw, (max-width: 1199px) 50vw, 25vw"
                                            />
                                        )}
                                    </Responsive>
                                    <div className="news-slide-date">
                                        <div className="text-xs">{slide.date}</div>
                                        <div className="text-xs">
                                            {slide.categories.map((category) => category.name).join(' / ')}
                                        </div>
                                    </div>
                                    <div className="news-slide-title text-s">
                                        <Typografed>{slide.title}</Typografed>
                                    </div>
                                    <div className="news-slide-btn">
                                        <ArrSVG />
                                    </div>
                                </Link>
                            </SwiperSlide>
                        ))}
                    </Swiper>
                    <Pagination
                        numSlides={numSlides}
                        currentRealIndex={currentRealIndex}
                        lastRealIndex={lastRealIndex}
                        prevElClass="news-slider-prev-btn"
                        nextElClass="news-slider-next-btn"
                        wrapperClass="pagination-wrapper news-pagination-wrapper wrapper"
                    />
                </div>
            ) : (
                <div className="news-bottom-no-slider wrapper swiper__wrapper">
                    {newsSectionProps.newsSlider.map((slide, i) => (
                        <Link
                            href={`/news/${slide.categories[0].slug}/${slide.slug}/`}
                            className="news-slider-slide-no-slider"
                            key={i}
                        >
                            <Responsive className="news-slider-img-wrapper">
                                {slide.img?.src && (
                                    <Image
                                        className="news-slider-img"
                                        src={slide.img.src}
                                        alt={slide.img.alt || ''}
                                        title={slide.img.title}
                                        width={slide.img.width}
                                        height={slide.img.height}
                                        sizes="(max-width: 767px) 80vw, (max-width: 1199px) 50vw, 25vw"
                                    />
                                )}
                            </Responsive>
                            <div className="news-slide-date">
                                <div className="text-xs">{slide.date}</div>
                                <div className="text-xs">
                                    {slide.categories.map((category) => category.name).join(' / ')}
                                </div>
                            </div>
                            <div className="news-slide-title text-s">
                                <Typografed>{slide.title}</Typografed>
                            </div>
                            <div className="news-slide-btn">
                                <ArrSVG />
                            </div>
                        </Link>
                    ))}
                </div>
            )}
        </section>
    );
}

export default NewsSection;
