import { useScroll } from 'framer-motion';
import { GetStaticProps, InferGetStaticPropsType } from 'next';
import { useRef } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { PAGES } from '@/consts';
import {
    AppCompaniesResponse,
    getCommonPageProps,
    getCompanies,
    getCompanyCategories,
    getNews,
    getPostCategories,
} from '@/shared/api';
import { getCommunityEvents } from '@/shared/api/community-events';
import { getDays } from '@/shared/api/days';
import { getEntertainments } from '@/shared/api/entertainments';
import { getHistory } from '@/shared/api/history';
import { getNumbers } from '@/shared/api/numbers';
import { getPageDetail } from '@/shared/api/page-detail';
import { getPeople } from '@/shared/api/people';
import { getReviews } from '@/shared/api/reviews';
import { getSiteSettings } from '@/shared/api/site-settings';
import { getTickets } from '@/shared/api/tickets';
import { useMediaQueryDeviceStateValue } from '@/shared/atoms/media-query-device';
import DefaultLayout from '@/shared/components/layout/DefaultLayout';
import EventsSection, { EventSectionProps } from '@/shared/components/sections/EventsSection';
import FirstSection, { FirstSectionProps } from '@/shared/components/sections/FirstSection';
import GradientSection, { GradientSectionProps } from '@/shared/components/sections/GradientSection';
import HowItWasSection, { HowItWasSectionProps } from '@/shared/components/sections/HowItWasSection';
import NewsSection, { NewsSectionProps } from '@/shared/components/sections/NewsSection';
import OtherEventsSection, { OtherEventsSectionProps } from '@/shared/components/sections/OtherEventsSection';
import ParticipantsSection, { ParticipantsSectionProps } from '@/shared/components/sections/ParticipantsSection';
import PartnersSection, { PartnersSectionProps } from '@/shared/components/sections/PartnersSection';
import ProgramSection, { ProgramSectionProps } from '@/shared/components/sections/ProgramSection';
import ReviewsSection, { ReviewsSectionProps } from '@/shared/components/sections/ReviewsSection';
import TarifSection, { TarifSectionProps } from '@/shared/components/sections/TarifSection';
import DynamicBG from '@/shared/components/shared/DynamicBG';
import ErrorMessage from '@/shared/components/shared/ErrorMessage';
import GradientBG from '@/shared/components/shared/GradientBG';
import { CommonPageProps, NonUndefined } from '@/shared/lib/types';
import { formatDate } from '@/shared/lib/utils/dates';

const IndexPage = ({
    gradientSectionProps,
    participantsSectionProps,
    howItWasSectionProps,
    otherEventsSectionProps,
    firstSectionProps,
    eventSectionProps,
    programSectionProps,
    reviewsSectionProps,
    tarifSectionProps,
    newsSectionProps,
    partnersSectionProps,
}: InferGetStaticPropsType<typeof getStaticProps>) => {
    const ref = useRef(null);
    const { scrollYProgress } = useScroll({ target: ref });
    const device = useMediaQueryDeviceStateValue();
    const isDesktop = device === 'desktop';

    return (
        <DefaultLayout currentPageId={PAGES.MAIN} ref={ref}>
            <GradientBG scrollYProgress={scrollYProgress} />
            <ErrorBoundary fallback={<ErrorMessage />}>
                <FirstSection data={firstSectionProps} />
            </ErrorBoundary>
            <ErrorBoundary fallback={<ErrorMessage />}>
                <GradientSection gradientSectionProps={gradientSectionProps} />
            </ErrorBoundary>
            <div className="dynamic-bg-wrapper">
                {isDesktop && <DynamicBG variant={'dark'} />}
                {howItWasSectionProps.slider.images.length > 0 && (
                    <ErrorBoundary fallback={<ErrorMessage />}>
                        <HowItWasSection theme="light" howItWasSectionProps={howItWasSectionProps} />
                    </ErrorBoundary>
                )}
                {participantsSectionProps.participantsSlider.length > 0 && (
                    <ErrorBoundary fallback={<ErrorMessage />}>
                        <ParticipantsSection
                            participantsSectionProps={participantsSectionProps}
                            className="js-header-theme-trigger js-bg-trigger-dark"
                            data-bg="#082E74"
                            data-theme="light"
                            data-gradient-bg="dark"
                        />
                    </ErrorBoundary>
                )}
            </div>
            <div className="dynamic-bg-wrapper">
                {isDesktop && <DynamicBG variant={'light'} />}
                {eventSectionProps.events.length > 0 && (
                    <ErrorBoundary fallback={<ErrorMessage />}>
                        <EventsSection eventSectionProps={eventSectionProps} data-bg="#fff" />
                    </ErrorBoundary>
                )}
                {programSectionProps.cards.length > 0 && (
                    <ErrorBoundary fallback={<ErrorMessage />}>
                        <ProgramSection programSectionProps={programSectionProps} data-bg="#dfe1e3" />
                    </ErrorBoundary>
                )}
                {reviewsSectionProps.cards.length > 0 && (
                    <ErrorBoundary fallback={<ErrorMessage />}>
                        <ReviewsSection
                            reviewsSectionProps={reviewsSectionProps}
                            className="js-bg-trigger-light js-header-theme-trigger"
                            data-bg="#deeffa"
                            data-theme="dark"
                            id="reviews"
                        />
                    </ErrorBoundary>
                )}
                {tarifSectionProps.cards.length > 0 && (
                    <ErrorBoundary fallback={<ErrorMessage />}>
                        <TarifSection tarifSectionProps={tarifSectionProps} />
                    </ErrorBoundary>
                )}
            </div>
            {partnersSectionProps.partners && partnersSectionProps.partners?.length > 0 && (
                <ErrorBoundary fallback={<ErrorMessage />}>
                    <PartnersSection partnersSectionProps={partnersSectionProps} theme="light" />
                </ErrorBoundary>
            )}
            {otherEventsSectionProps.otherEventsSlider.length > 0 && (
                <ErrorBoundary fallback={<ErrorMessage />}>
                    <OtherEventsSection otherEventsSectionProps={otherEventsSectionProps} />
                </ErrorBoundary>
            )}
            {newsSectionProps.newsSlider.length > 0 && (
                <ErrorBoundary fallback={<ErrorMessage />}>
                    <NewsSection newsSectionProps={newsSectionProps} />
                </ErrorBoundary>
            )}
        </DefaultLayout>
    );
};

export default IndexPage;

type IndexPageProps = NonUndefined<
    CommonPageProps & {
        howItWasSectionProps: HowItWasSectionProps;
        firstSectionProps: FirstSectionProps;
        gradientSectionProps: GradientSectionProps;
        participantsSectionProps: ParticipantsSectionProps;
        videoClipSectionProps: {
            video: string;
        };
        otherEventsSectionProps: OtherEventsSectionProps;
        eventSectionProps: EventSectionProps;
        programSectionProps: ProgramSectionProps;
        reviewsSectionProps: ReviewsSectionProps;
        tarifSectionProps: TarifSectionProps;
        newsSectionProps: NewsSectionProps;
        partnersSectionProps: PartnersSectionProps;
    }
>;

export const getStaticProps: GetStaticProps<IndexPageProps> = async () => {
    const pageResponse = await getPageDetail({ params: { slug: 'main' } });

    const [
        commonPageProps,
        newsResponse,
        companiesResponse,
        peopleResponse,
        reviewsResponse,
        historyResponse,
        numbersResponse,
        entertainmentsResponse,
        communityEventsResponse,
        daysResponse,
        ticketsResponse,
        siteSettings,
    ] = await Promise.all([
        getCommonPageProps(),
        getPostCategories().then((postCategoriesResponse) =>
            getNews({ params: { limit: 20 }, postCategories: postCategoriesResponse.data || [] }),
        ),
        getCompanyCategories({ params: { page: pageResponse.data?.id ?? PAGES.MAIN } }).then((categoriesResponse) => {
            if (Array.isArray(categoriesResponse.data) && categoriesResponse.data.length === 0) {
                return Promise.resolve<AppCompaniesResponse>({
                    status: 'success',
                    data: [],
                });
            }

            return getCompanies({
                params: { categories: (categoriesResponse.data ?? []).map((category) => category.id) },
            });
        }),
        getPeople({ params: { page: pageResponse.data?.id ?? PAGES.MAIN } }),
        getReviews({ params: { page: pageResponse.data?.id ?? PAGES.MAIN } }),
        getHistory({ params: { page: pageResponse.data?.id ?? PAGES.MAIN } }),
        getNumbers(),
        getEntertainments(),
        getCommunityEvents({ params: { show_main: 1 } }),
        getDays({ params: { page: pageResponse.data?.id ?? PAGES.MAIN } }),
        getTickets({ params: { page: pageResponse.data?.id ?? PAGES.MAIN } }),
        getSiteSettings({
            params: {
                keys: [
                    'main_first_screen_title',
                    'main_first_screen_big_text',
                    'main_first_screen_place',
                    'main_first_screen_date',
                    'main_first_screen_description',

                    'main_video_short',
                    'main_video_long',

                    'main_history_title',
                    'main_history_description',
                    'main_history_link_text',
                    'main_history_link',

                    'main_people_title',
                    'main_people_link_text',
                    'main_people_link',

                    'main_event_title',

                    'main_program_title',
                    'main_program_description',
                    'main_program_link_text',
                    'main_program_link',

                    'main_review_title',
                    'main_review_description',

                    'main_tariff_title',
                    'main_tariff_description',
                    'main_tariff_link_text',
                    'main_tariff_link',

                    'main_partner_title',
                    'main_partner_description',
                    'main_partner_button_text',

                    'main_other_event_title',
                    'main_other_event_description',
                    'main_other_event_link_text',
                    'main_other_event_link',

                    'main_post_title',
                    'main_post_link_text',
                    'main_post_link',
                ],
            },
        }),
    ]);

    return {
        props: {
            ...commonPageProps,
            bodyClass: 'index-page',
            meta: {
                ...commonPageProps.meta,
                ...(pageResponse.data?.meta ?? []),
            },
            gradientSectionProps: {
                bigNumbers: numbersResponse.data ?? [],
                shortVideo: siteSettings.data?.main_video_short || [
                    {
                        src: '/video/main_video - 27.mp4',
                        type: 'video/mp4',
                    },
                ],
                longVideo: siteSettings.data?.main_video_long || [
                    {
                        src: '/video/TNF_2024_short-23.mp4',
                        type: 'video/mp4',
                    },
                ],
            },
            videoClipSectionProps: {
                video: '/video/main-video.mp4',
            },
            firstSectionProps: {
                video: [{ src: '/video/output-opt.mp4', type: 'video/mp4' }],
                poster: {
                    src: '/video/output-opt-poster.jpg',
                    width: 1920,
                    height: 1080,
                },
                title: siteSettings.data?.main_first_screen_big_text || 'tnf 2025',
                info: siteSettings.data?.main_first_screen_title || 'промышленно – энергетический форум',
                location: siteSettings.data?.main_first_screen_place || 'Тюмень /',
                date: siteSettings.data?.main_first_screen_date || '15-18 сентября',
                text:
                    siteSettings.data?.main_first_screen_description ||
                    'TNF — главная площадка нефтегазовой отрасли в России, объединяющая производителей оборудования и услуг, недропользователей, структуры, определяющие государственную промышленно-технологическую политику.',
            },
            howItWasSectionProps: {
                slider: historyResponse.data ?? { images: [] },
                title: siteSettings.data?.main_history_title || 'Как это было',
                description:
                    siteSettings.data?.main_history_description ||
                    'В 2024 на площадке TNF были презентованы более сотни новых технологий, представлены разработки по 5 дорожным картам импортозамещения в нефтегазе, проведено около 200 бизнес-встреч и обсуждены сотни актуальных для отрасли вопросов.',
                link: siteSettings.data?.main_history_link
                    ? {
                          href: siteSettings.data?.main_history_link,
                          name: siteSettings.data?.main_history_link_text ?? '',
                      }
                    : null,
            },
            participantsSectionProps: {
                title: siteSettings.data?.main_people_title || 'Постоянные участники',
                participantsSlider: (peopleResponse.data ?? []).map((person) => ({
                    name: person.name,
                    job: person.job,
                    img: person.img,
                })),
            },
            otherEventsSectionProps: {
                otherEventsSlider: communityEventsResponse.data?.items ?? [],
                title: siteSettings.data?.main_other_event_title || 'Другие мероприятия команды TNF',
                description:
                    siteSettings.data?.main_other_event_description ||
                    'На протяжении всего года в рамках TNF-экосистемы проводятся нишевые мероприятия, предоставляющие уникальные возможности для развития и общения профессионалов из нефтегазовой сферы.',
            },
            newsSectionProps: {
                title: siteSettings.data?.main_post_title || 'Новости',
                btnSlug: '/news',
                newsSlider: newsResponse.data?.items || [],
            },
            eventSectionProps: {
                title: siteSettings.data?.main_event_title || 'События форума TNF 2024',
                events: entertainmentsResponse.data ?? [],
            },
            programSectionProps: {
                title: siteSettings.data?.main_program_title || 'Программа',
                subtitle:
                    siteSettings.data?.main_program_description ||
                    'Программа форума разделена на 4 дня, каждый из которых посвящен определенной теме',
                cards: (daysResponse.data ?? []).map((day) => ({
                    date: formatDate(new Date(day.publishedAt), '.', false),
                    title: day.name,
                    description: day.description ?? '',
                    dayId: day.id,
                })),
            },
            reviewsSectionProps: {
                title: siteSettings.data?.main_review_title || 'О нас говорят',
                subtitle:
                    siteSettings.data?.main_review_description ||
                    'Форум TNF 2024 стал кульминацией года в нефтегазовой промышленности, объединив ведущих экспертов и ключевых игроков отрасли в одном месте',
                cards: reviewsResponse.data ?? [],
            },
            tarifSectionProps: {
                title: siteSettings.data?.main_tariff_title || 'Тарифы',
                subtitle:
                    siteSettings.data?.main_tariff_description ||
                    'Для участия зарегистрируйтесь или войдите в личный кабинет',
                cards: (ticketsResponse.data ?? []).map((ticket) => ({
                    title: ticket.title,
                    description: ticket.description,
                    href: '/participation/participants/#tarifs',
                })),
            },
            partnersSectionProps: {
                title: siteSettings.data?.main_partner_title || 'Ключевые партнеры форума',
                description:
                    siteSettings.data?.main_partner_description ||
                    'Партнер TNF - это почетный статус, присваивается компаниям, которые поддерживают различные форматы Форума - от культурных до деловых. Партнеры получают усиленную информационную и дизайн-поддержку в брендировании мероприятий и уникальные инструменты продвижения.',
                partners: (companiesResponse.data ?? []).map((company) => ({
                    logo: company.mainLogo || null,
                })),
            },
        } satisfies IndexPageProps,
        revalidate: 60,
    };
};
