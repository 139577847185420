import { useScroll, useTransform } from 'framer-motion';
import { useRef } from 'react';
import { useMediaQueryDeviceStateValue } from '@/shared/atoms/media-query-device';
import BigNumber, { BigNumberType } from '@/shared/components/shared/BigNumber';
import { VideoShape } from '@/shared/lib/types';
import VideoClipSection from '../VideoClipSection';

export type GradientSectionProps = {
    bigNumbers: BigNumberType[];
    shortVideo: VideoShape;
    longVideo: VideoShape;
};

type Props = React.HTMLAttributes<HTMLElement> & {
    gradientSectionProps: GradientSectionProps;
};

const GradientSection = ({ gradientSectionProps }: Props) => {
    const device = useMediaQueryDeviceStateValue();
    const isMobile = device.includes('mobile');
    const isTablet = device.includes('tablet');
    const isDesktop = device === 'desktop';
    const startPosition1 = 0;
    const startPosition2 = 360;
    const endPosition = isDesktop ? 0 : isTablet ? -100 : isMobile ? 140 : -140;
    const endPosition2 = isDesktop ? 0 : isTablet ? 100 : isMobile ? 280 : 0;

    const wrapperRef = useRef<HTMLDivElement>(null);
    const { scrollYProgress } = useScroll({ target: wrapperRef });

    const SCROLL_PROGRESS_END = isTablet ? 0.25 : isMobile ? 0.3 : 0.5;
    const titleTranslate = useTransform(scrollYProgress, [0, SCROLL_PROGRESS_END], [startPosition1, endPosition]);
    const titleTranslate2 = useTransform(
        scrollYProgress,
        [isMobile ? -0.4 : 0, SCROLL_PROGRESS_END],
        [startPosition2, endPosition2],
    );
    return (
        <section className="gradient-section-wrapper" ref={wrapperRef}>
            <div className="gradient-section-bottom wrapper">
                {gradientSectionProps.bigNumbers.map((item, i) => (
                    <BigNumber
                        number={item}
                        key={i}
                        isDesktop={isDesktop}
                        index={i}
                        titleTranslate={titleTranslate}
                        titleTranslate2={titleTranslate2}
                    />
                ))}
            </div>
            <VideoClipSection
                scrollYProgress={scrollYProgress}
                shortVideo={gradientSectionProps.shortVideo[0].src}
                longVideo={gradientSectionProps.longVideo[0].src}
            />
        </section>
    );
};

export default GradientSection;
