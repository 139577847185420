import classNames from 'classnames';
import { useState } from 'react';
import { useRef } from 'react';
import { Navigation } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Participant } from '@/entities';
import Pagination from '@/shared/components/shared/Pagination';
import ParticipantSlide from '@/shared/components/shared/ParticipantSlide';
import { useMounted } from '@/shared/hooks/use-mounted';
import { useOverflowing } from '@/shared/hooks/use-overflowing';
import { useTypograf } from '@/shared/hooks/use-typograf';

export type ParticipantsSectionProps = {
    participantsSlider: Participant[];
    title: string;
};

type Props = React.HTMLAttributes<HTMLElement> & {
    participantsSectionProps: ParticipantsSectionProps;
};

function ParticipantsSection({ participantsSectionProps, ...props }: Props) {
    const [lastRealIndex, setLastRealIndex] = useState(0);
    const [currentRealIndex, setCurrentRealIndex] = useState(0);
    const numSlides = participantsSectionProps.participantsSlider.length;
    const tpTitle = useTypograf(participantsSectionProps.title);

    const slider = useRef<HTMLDivElement | null>(null);
    const isInitialized = useOverflowing(slider);
    const isMounted = useMounted();

    return (
        <section {...props} className={classNames('participants-section', props.className)}>
            <div className="participants-top wrapper">
                <h2 className="participants-title h3">{tpTitle}</h2>
            </div>
            {isInitialized && isMounted ? (
                <div className="participants-bottom">
                    <Swiper
                        className="participants-slider"
                        modules={[Navigation]}
                        navigation={{
                            prevEl: '.participants-slider-prev-btn',
                            nextEl: '.participants-slider-next-btn',
                        }}
                        speed={800}
                        slidesPerView={'auto'}
                        breakpoints={{
                            320: {
                                spaceBetween: 12,
                                slidesOffsetBefore: 16,
                            },
                            769: {
                                spaceBetween: 12,
                                slidesOffsetBefore: 22,
                            },
                            1201: {
                                spaceBetween: 83,
                                slidesOffsetBefore: 70,
                                slidesOffsetAfter: 70,
                            },
                        }}
                        onSlideChange={(swiper) => {
                            const newRealIndex = swiper.realIndex;
                            setLastRealIndex(currentRealIndex);
                            setCurrentRealIndex(newRealIndex);
                        }}
                    >
                        {participantsSectionProps.participantsSlider.map((slide, i) => (
                            <SwiperSlide className="participants-slider-slide" key={i}>
                                <ParticipantSlide slide={slide} />
                            </SwiperSlide>
                        ))}
                    </Swiper>
                    <Pagination
                        numSlides={numSlides}
                        currentRealIndex={currentRealIndex}
                        lastRealIndex={lastRealIndex}
                        prevElClass="participants-slider-prev-btn"
                        nextElClass="participants-slider-next-btn"
                        wrapperClass="pagination-wrapper wrapper"
                    />
                </div>
            ) : (
                <div className="wrapper participants-slider__wrapper swiper__wrapper" ref={slider}>
                    {participantsSectionProps.participantsSlider.map((slide, i) => (
                        <div className="participants-slider-slide" key={i}>
                            <ParticipantSlide slide={slide} />
                        </div>
                    ))}
                </div>
            )}
        </section>
    );
}

export default ParticipantsSection;
