import React from 'react';
import { Participant } from '@/entities';
import Image from '@/shared/components/shared/Image';
import { useTypograf } from '@/shared/hooks/use-typograf';
import Responsive from '../Responsive';

interface Props {
    slide: Participant;
}

function ParticipantSlide({ slide }: Props) {
    const tpName = useTypograf(slide.name);
    const tpJob = useTypograf(slide.job);

    return (
        <>
            <Responsive className="participants-slider-img-wrapper">
                {slide.img?.src && (
                    <Image
                        className="participants-slider-img"
                        src={slide.img.src}
                        alt={slide.img.alt || ''}
                        title={slide.img.title}
                        width={slide.img.width}
                        height={slide.img.height}
                        sizes="(max-width: 1199px) 20vw, 30vw"
                    />
                )}
            </Responsive>
            <div className="participant-slide-name text-m">{tpName}</div>
            <div className="participant-slide-job text-xs">{tpJob}</div>
        </>
    );
}

export default ParticipantSlide;
