import { useState } from 'react';
import Marquee from 'react-fast-marquee';
import { Partner } from '@/entities/partner';
import { useMediaQueryDeviceStateValue } from '@/shared/atoms/media-query-device';
import { useSetOpenedPopupsState } from '@/shared/atoms/opened-popups';
import { ORGANIZE_FORM_POPUP } from '@/shared/components/popups/OrganizeFormPopup/OrganizeFormPopup';
import Button from '@/shared/components/shared/Button';
import Link from '@/shared/components/shared/Link';
import { useTypograf } from '@/shared/hooks/use-typograf';
import { Theme } from '@/shared/lib/types';
import OrganizeDetailPopup from '../../popups/OrganizeDetailPopup';
import { ORGANIZE_DETAIL_POPUP } from '../../popups/OrganizeDetailPopup/OrganizeDetailPopup';
import OrganizeFormPopup from '../../popups/OrganizeFormPopup';
import Image from '../../shared/Image';
import Typografed from '../../shared/Typografed';

export type PartnersSectionProps = {
    title: string;
    description?: string;
    partners?: Partner[];
};

type Props = React.HTMLAttributes<HTMLElement> & {
    partnersSectionProps: PartnersSectionProps;
    theme?: Theme;
    isRenderWithPopup?: boolean;
};

function PartnersSection({ partnersSectionProps, theme, isRenderWithPopup }: Props) {
    const device = useMediaQueryDeviceStateValue();
    const tpTitle = useTypograf(partnersSectionProps.title);

    const [selectedPartner, setSelectedPartner] = useState<Partner | null>(null);
    const { openPopup } = useSetOpenedPopupsState();

    const LINK_CARDS = '/participation/partners#organize';

    const handleClick = (partner: Partner) => {
        setSelectedPartner(partner);
        openPopup(ORGANIZE_DETAIL_POPUP);
    };

    return (
        <section className="partners-section js-header-theme-trigger" data-theme={theme}>
            <div className="partners-top wrapper">
                <h2 className="partners-title h3">{tpTitle}</h2>
                <div className="partners-description-block">
                    <Button
                        onClick={() => {
                            openPopup(ORGANIZE_FORM_POPUP);
                        }}
                        variant="outline-light"
                    >
                        Стать партнёром
                    </Button>
                </div>
            </div>
            <div className="partners-center">
                {isRenderWithPopup && partnersSectionProps.partners ? (
                    <Marquee className="partners-cards partners-cards-with-popup" pauseOnHover={device === 'desktop'}>
                        {partnersSectionProps.partners.map((item, i) => (
                            <button
                                className="partners-card"
                                key={i}
                                aria-label="Перейти к партнеру"
                                onClick={() => handleClick(item)}
                            >
                                {item.logo?.src && (
                                    <Image
                                        className="partners-card__img"
                                        src={item.logo.src}
                                        alt={item.logo.alt || ''}
                                        title={item.logo.title}
                                        width={item.logo.width}
                                        height={item.logo.height}
                                        sizes="200px"
                                        unoptimized={item.logo.src.endsWith('.svg')}
                                        priority
                                    />
                                )}
                            </button>
                        ))}
                    </Marquee>
                ) : (
                    <Marquee
                        className="partners-cards partners-cards-without-popup"
                        pauseOnHover={device === 'desktop'}
                    >
                        {partnersSectionProps.partners &&
                            partnersSectionProps.partners.map((item, i) => (
                                <Link
                                    href={LINK_CARDS}
                                    className="partners-card"
                                    key={i}
                                    aria-label="Перейти к партнерам"
                                >
                                    {item.logo?.src && (
                                        <Image
                                            className="partners-card__img"
                                            src={item.logo.src}
                                            alt={item.logo.alt || ''}
                                            title={item.logo.title}
                                            width={item.logo.width}
                                            height={item.logo.height}
                                            sizes="200px"
                                            unoptimized={item.logo.src.endsWith('.svg')}
                                            priority
                                        />
                                    )}
                                </Link>
                            ))}
                    </Marquee>
                )}
            </div>
            {partnersSectionProps.description && (
                <div className="partners-bottom wrapper">
                    <div className="partners-bottom-text text-s">
                        <Typografed>{partnersSectionProps.description}</Typografed>
                    </div>
                </div>
            )}
            <OrganizeFormPopup />
            {isRenderWithPopup && <OrganizeDetailPopup partner={selectedPartner} />}
        </section>
    );
}

export default PartnersSection;
