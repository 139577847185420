import { MotionValue, m as motion, useTransform } from 'framer-motion';
import { Entertainment } from '@/entities/entertainment';
import Image from '@/shared/components/shared/Image';
import Responsive from '@/shared/components/shared/Responsive';

interface Props {
    index: number;
    isDesktop: boolean;
    isTablet: boolean;
    scrollYProgress: MotionValue;
    eventsAmount: number;
    event: Entertainment;
}

const EventCard = ({ index, scrollYProgress, eventsAmount, event, isDesktop, isTablet }: Props) => {
    const y = useTransform(
        scrollYProgress,
        [
            index / eventsAmount,
            (index + 1) / eventsAmount,
            (index + 2) / eventsAmount,
            (index + 3) / eventsAmount,
            (index + 4) / eventsAmount,
        ],
        [
            isDesktop ? 150 : isTablet ? 100 : 50,
            isDesktop ? 100 : isTablet ? 50 : 30,
            isDesktop ? 50 : isTablet ? 25 : 10,
            0,
            0,
        ],
    );

    const scale = useTransform(
        scrollYProgress,
        [
            index / eventsAmount,
            (index + 1) / eventsAmount,
            (index + 2) / eventsAmount,
            (index + 3) / eventsAmount,
            (index + 4) / eventsAmount,
        ],
        [1, 1, 0.9, 0.8, 0.7],
    );

    const opacity = useTransform(
        scrollYProgress,
        [
            index / eventsAmount,
            (index + 1) / eventsAmount,
            (index + 2) / eventsAmount,
            (index + 3) / eventsAmount,
            (index + 4) / eventsAmount,
        ],
        [1, 1, 1, 1, 0],
    );

    return (
        <motion.div key={index} style={{ y, scale, opacity }} className="event-card-wrapper">
            <Responsive className="event-card">
                {event.img?.src && (
                    <Image
                        src={event.img.src}
                        className="responsive__item"
                        alt={event.img.alt || event.title || ''}
                        title={event.img.title}
                        height={event.img.height}
                        width={event.img.width}
                        sizes="(max-width: 1199px) 100vw, 60vw"
                    />
                )}
            </Responsive>
        </motion.div>
    );
};

export default EventCard;
