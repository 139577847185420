import { m as motion } from 'framer-motion';
import React, { useEffect, useRef, useState } from 'react';

interface Props {
    variant: string;
}

const DynamicBG = ({ variant }: Props) => {
    const ref = useRef<HTMLDivElement>(null);
    const [theme, setTheme] = useState('transparent');
    const observersRef = useRef<IntersectionObserver[]>([]);

    useEffect(() => {
        const createObserverForElement = (el: HTMLElement) => {
            const dataOffset = el.dataset.offset;
            const rootMargin = dataOffset === 'none' ? '-100% 0% 0% 0%' : '-60% 0% -40% 0%';

            const observer = new IntersectionObserver(
                (entries) => {
                    entries.forEach((entry) => {
                        const target = entry.target as HTMLElement;
                        if (entry.isIntersecting) {
                            const theme = target.dataset.bg as string;
                            setTheme(theme);
                        }
                    });
                },
                { rootMargin, threshold: [0, 1] },
            );

            observer.observe(el);
            observersRef.current.push(observer);
        };

        const observeAllElements = () => {
            observersRef.current.forEach((observer) => observer.disconnect());
            observersRef.current = [];

            document.querySelectorAll<HTMLElement>(`.js-bg-trigger-${variant}`).forEach((el) => {
                createObserverForElement(el);
            });
        };

        observeAllElements();
        document.addEventListener('new-page-ready', observeAllElements);

        return () => {
            observersRef.current.forEach((observer) => observer.disconnect());
            document.removeEventListener('new-page-ready', observeAllElements);
        };
    }, [variant]);

    return <motion.div style={{ backgroundColor: theme }} className="dynamic-bg" ref={ref}></motion.div>;
};

export default DynamicBG;
