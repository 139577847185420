import classNames from 'classnames';
import { InputHTMLAttributes } from 'react';

interface Props extends InputHTMLAttributes<HTMLInputElement> {
    /**
     * Валиден ли инпут
     */
    valid?: boolean;
    /**
     * Вид чекбокса
     */
    checkboxStyle?: 'square' | 'check';
}

const Checkbox = ({ children, valid = true, checkboxStyle = 'check', ...props }: Props) => {
    return (
        <label className={classNames('text-xs checkbox', `checkbox--${checkboxStyle}`, props.className)}>
            <input {...props} type="checkbox" className={classNames('visually-hidden', { 'is-error': !valid })} />
            <span className="checkbox__element"></span>
            <span className="checkbox__text">{children}</span>
        </label>
    );
};

export default Checkbox;
