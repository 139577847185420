import React from 'react';
import Button from '@/shared/components/shared/Button';
import { useTypograf } from '@/shared/hooks/use-typograf';
import Link from '../Link';

interface Props {
    card: {
        title: string;
        description: string;
        href: string;
    };
}

function TarifCard({ card }: Props) {
    const tpTitle = useTypograf(card.title);
    const tpDescription = useTypograf(card.description);

    return (
        <Link href={card.href} className="tarif-card">
            <div className="tarif-card-top">{tpTitle && <div className="tarif-card-title h4">{tpTitle}</div>}</div>
            <div className="tarif-card-bottom">
                {tpDescription && (
                    <div
                        className="tarif-card-description text-s"
                        dangerouslySetInnerHTML={{ __html: tpDescription }}
                    ></div>
                )}
                <Button tag="span" className="tarif-card-btn" variant="dark-filled">
                    Подробнее
                </Button>
            </div>
        </Link>
    );
}

export default TarifCard;
