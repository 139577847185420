import { m as motion, useMotionValueEvent, useScroll } from 'framer-motion';
import { useRef, useState } from 'react';
import { Entertainment } from '@/entities/entertainment';
import { useMediaQueryDeviceStateValue } from '@/shared/atoms/media-query-device';
import Button from '@/shared/components/shared/Button';
import EventCard from '@/shared/components/shared/EventCard/EventCard';
import Image from '@/shared/components/shared/Image';
import Link from '@/shared/components/shared/Link';
import Responsive from '@/shared/components/shared/Responsive';
import { useTypograf } from '@/shared/hooks/use-typograf';
import Typografed from '../../shared/Typografed';

export type EventSectionProps = {
    title: string;
    events: Entertainment[];
};

type Props = React.HTMLAttributes<HTMLElement> & {
    eventSectionProps: EventSectionProps;
};

const EventsSection = ({ eventSectionProps, ...props }: Props) => {
    const device = useMediaQueryDeviceStateValue();
    const isTablet = device.includes('tablet');
    const isDesktop = device === 'desktop';
    const ref = useRef(null);
    const { scrollYProgress } = useScroll({ target: ref });
    const [currentIndex, setCurrentIndex] = useState(0);
    const totalEvents = eventSectionProps.events.length;
    const step = 1 / totalEvents;
    const tpTitle = useTypograf(eventSectionProps.title);

    useMotionValueEvent(scrollYProgress, 'change', (progress) => {
        const newIndex = Math.min(Math.floor((progress / step) * 0.8), totalEvents - 1);
        setCurrentIndex(newIndex);
    });

    return (
        <section
            className="events-section-wrapper wrapper js-header-theme-trigger js-bg-trigger-light"
            data-bg="#fff"
            data-theme="dark"
            data-offset="none"
            ref={ref}
            id="events"
            {...props}
        >
            {!isDesktop ? (
                <>
                    <h2 className="events-title h3">{tpTitle}</h2>

                    <ul className="events-cards-wrapper list-unstyled">
                        {eventSectionProps.events.map((item, index) => (
                            <li key={index}>
                                <div className="event-card-wrapper">
                                    <Responsive className="event-card">
                                        {item.img?.src && (
                                            <Image
                                                src={item.img.src}
                                                className="responsive__item"
                                                alt={item.img.alt || ''}
                                                width={item.img.width}
                                                height={item.img.height}
                                                sizes="(max-width: 1199px) 100vw, 60vw"
                                            />
                                        )}
                                    </Responsive>
                                </div>
                                <div className="events-info-content">
                                    <div className="events-info-title h4">
                                        <Typografed>{item.title}</Typografed>
                                    </div>
                                    {item.description && (
                                        <div className="events-info-description text-s">
                                            <Typografed>{item.description}</Typografed>
                                        </div>
                                    )}
                                    {item.href && (
                                        <Button
                                            tag={Link}
                                            href={item.href}
                                            target="_blank"
                                            rel="noreferrer nofollow"
                                            variant={`outline-dark`}
                                            className="event-btn"
                                        >
                                            {item.linkText}
                                        </Button>
                                    )}
                                </div>
                            </li>
                        ))}
                    </ul>
                </>
            ) : (
                <>
                    <div className="events-left">
                        <h2 className="events-title h3">{tpTitle}</h2>

                        <motion.div className="events-info">
                            {eventSectionProps.events.map((item, index) => {
                                const isCurrent = index === currentIndex;
                                const y = isCurrent ? 0 : index > currentIndex ? 100 : -100;
                                const opacity = isCurrent ? 1 : 0;

                                return (
                                    <motion.div
                                        key={index}
                                        className="events-info-content"
                                        style={{ position: 'absolute', y, opacity }}
                                        animate={{ y, opacity }}
                                        transition={{ duration: 0.3, ease: 'easeInOut' }}
                                    >
                                        <div className="events-info-title h4">
                                            <Typografed>{item.title}</Typografed>
                                        </div>
                                        {item.description && (
                                            <div className="events-info-description text-s">
                                                <Typografed>{item.description}</Typografed>
                                            </div>
                                        )}
                                        {item.href && (
                                            <Button
                                                tag={Link}
                                                href={item.href}
                                                target="_blank"
                                                rel="noreferrer nofollow"
                                                variant={`outline-dark`}
                                                className="event-btn"
                                            >
                                                {item.linkText}
                                            </Button>
                                        )}
                                    </motion.div>
                                );
                            })}
                        </motion.div>
                    </div>

                    <div className="events-right">
                        <div className="events-cards-wrapper list-unstyled">
                            {eventSectionProps.events.map((item, index) => (
                                <EventCard
                                    key={index}
                                    index={index}
                                    scrollYProgress={scrollYProgress}
                                    eventsAmount={totalEvents}
                                    event={item}
                                    isDesktop={isDesktop}
                                    isTablet={isTablet}
                                />
                            ))}
                        </div>
                    </div>
                </>
            )}
        </section>
    );
};

export default EventsSection;
