import classNames from 'classnames';
import { MotionValue, m as motion, useTransform } from 'framer-motion';
import React from 'react';
import { useTypograf } from '@/shared/hooks/use-typograf';

export type BigNumberType = {
    title: string;
    subtitle: string;
    stroke: boolean;
};

interface Props {
    number: BigNumberType;
    titleTranslate: MotionValue;
    titleTranslate2: MotionValue;
    index: number;
    isDesktop: boolean;
}

function BigNumber({ number, titleTranslate, titleTranslate2, index, isDesktop }: Props) {
    const tpTitle = useTypograf(number.title);
    const tpSubtitle = useTypograf(number.subtitle);
    const transformedTitleTranslate = useTransform(titleTranslate, (val) => val * 0.7);

    const newTitleTranslate = index === 0 && isDesktop ? transformedTitleTranslate : titleTranslate;
    return (
        <motion.div
            className="gradient-section-bottom-item"
            style={{ y: number.stroke ? newTitleTranslate : titleTranslate2 }}
        >
            <div className={classNames('number-big', { 'number-stroke': number.stroke })}>{tpTitle}</div>
            <div className="text-s big-numbers-text">{tpSubtitle}</div>
        </motion.div>
    );
}

export default BigNumber;
